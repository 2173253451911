import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_BY_TAG } from '../GraphQL/mutation'; // Update path if needed
import { FaRegEye, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Navbar from './Navbar';
import Hero from './ShopHero';
import Footer from './Footer';
import Product from './Product';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addItemToCart } from '../store/reducers/cart';

const ShopAllCategories = () => {
  const [categories, setCategories] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const { loading, data, error } = useQuery(GET_PRODUCTS_BY_TAG, {
    variables: { tagIds: ['665c45337ecf78a297ea8739', '666590232aadf91263ca1147', '6697a17dbe5276fe49d787fc'] },
  });

  useEffect(() => {
    if (data) {
      const categoriesData = data.getProductsByTag.data.map((tag) => ({
        tagName: tag.tagName,
        products: tag.products.map(product => ({
          ...product,
          categoryName: product.category ? product.category.categoryName : 'Unknown', // Handle null case
        })) || [],
      }));
      setCategories(categoriesData);
    }
  }, [data]);

  const addToCartHandler = (product) => {
    const isAlreadyInCart = cartItems.some((item) => item.id === product.slug);

    if (isAlreadyInCart) {
      toast.error('Product is already in the cart!');
    } else {
      dispatch(
        addItemToCart({
          id: product.slug,
          name: product.productName,
          imageName: product.listImagePath?.[0] || '', // Handle null or empty array
          price: product.discountedPrice,
          quantity: 1,
        })
      );
      toast.success('Product added to cart successfully!');
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 6 } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button className="absolute bg-slate-50 shadow-sm rounded-full p-1 -top-10 right-0 text-[#F16427]" onClick={onClick}>
        <FaChevronRight size={18} />
      </button>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="absolute bg-slate-50 shadow-sm rounded-full p-1 -top-10 right-8 text-[#F16427]" onClick={onClick}>
        <FaChevronLeft size={18} />
      </button>
    );
  }

  const ProductCard = ({ product }) => (
    <div className="prod-wrapper mx-2 cursor-pointer">
      <div className="relative">
        <div className="img flex h-48 justify-center items-center text-center bg-[#EDEDED] rounded-lg overflow-hidden">
          {product.listImagePath?.length > 0 ? (
            <img
              src={`https://storage.googleapis.com/awkat-o-salat/${product.listImagePath[0]}`}
              alt={product.productName}
              className="w-full h-full object-cover"
            />
          ) : (
            <p className="text-gray-500">No Image Available</p>
          )}
        </div>

        <button
          className="absolute top-2 right-2 text-[#F16427] bg-white text-xs font-bold py-2 px-3 rounded"
          onClick={(e) => {
            e.stopPropagation();
            addToCartHandler(product);
          }}
        >
          + Add
        </button>

        <button
          onClick={() => {
            setSelectedProductId(product.productId);
            setIsModalOpen(true);
          }}
          className="absolute top-100 right-5 text-[#F16427] bg-white rounded-full p-2"
        >
          <FaRegEye size={24} />
        </button>
      </div>

      <h4 className="prod-title text-sm font-semibold mt-2 text-[#273B60] truncate">{product.productName}</h4>
      <div className="prod-info mt-1">
        <h4 className="prod-price text-sm font-semibold text-[#273B60]">
          ${product.discountedPrice.toFixed(2)}
          <span className="text-xs text-gray-500 line-through ml-1">
            ${product.price.toFixed(2)}
          </span>
        </h4>
      </div>
    </div>
  );

  if (error) return <p>Error loading products</p>;

  return (
    <>
      <Navbar />
      <Hero heading="Shop All" className="mb-20" />
      <div className="container mx-auto px-4 mt-14 mb-20">
        {loading ? (
          <div>Loading...</div>
        ) : categories.length === 0 ? (
          <p>No products available</p>
        ) : (
          categories.map((category, index) => (
            <div key={index} className="mb-20">
              <h2 className="text-2xl font-bold capitalize">{category.tagName}</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {category.products.length > 0 ? (
                  category.products.map((product) => <ProductCard key={product.slug} product={product} />)
                ) : (
                  <p>No products available</p>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      <Footer />

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedProductId && (
          <Product
            prodId={selectedProductId} // Pass the selected productId to the Product component
            productsData={categories.flatMap((category) => category.products)}
          />
        )}
      </Modal>

      <ToastContainer />
    </>
  );
};

export default ShopAllCategories;
