import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import { Animation } from './components/Animation';
import Login from './screens/Login';
import SignUp from './screens/Signup';
import ResetPassword from './screens/ResetPassword';
import Pricing from './components/Pricing';
import { useDispatch, useSelector } from 'react-redux';
import CustomerWebsite from './routes/customer';
import BusinessWebsite from './routes/business';
import { AuthProvider } from './auth/authContext';
import Shop from './components/Shop';
import Products from './components/Products';
import Services from './screens/Services';
import { useEffect } from 'react';
import { GET_TAGS_DATA } from './GraphQL/mutation';
import { setBusinessInfo } from './store/reducers/business';
import BestDeals from './components/BestDeals';
import ProductDetails from './components/ProductDetails';
import ShopAllCategories from './components/ShopAllCategories';
import TagProducts from './components/TagProducts';
import client from './GraphQL/ApolloClient';
import { setProductTags } from './store/reducers/product';
import About from './components/About';
import Cart from './components/Cart';
import Checkout from './screens/Checkout';
import OrderConfirmation from './screens/ConfirmOrder';
import UpdateProfile from './screens/UpdateProfile';


function App() {
  const dispatch = useDispatch();
  const { webInterface, isAuthorized } = useSelector((state) => state.main); // Include isAuthorized from state

  // Load token from localStorage when the app loads
  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('userName');

    if (token) {
      dispatch(setBusinessInfo({ username, access_token: token }));
    }
  }, [dispatch]);

  // Fetch product tags on app initialization
  useEffect(() => {
    const fetchTagsData = async () => {
      try {
        const response = await client.query({ query: GET_TAGS_DATA });
        dispatch(setProductTags(response.data.getTagsData.data));
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };

    fetchTagsData();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Animation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          {/* Inline Protected Routes */}
          <Route path="/pricing" element={isAuthorized ? <Pricing /> : <Navigate to="/login" />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/pp/:slug" element={<ProductDetails />} />
          <Route path="/best-deals" element={<BestDeals />} />
          <Route path="/shop-all" element={<ShopAllCategories />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/orderconfirmation" element={<OrderConfirmation />} />

          {/* Customer/Business Routes */}
          <Route path="/customer/*" element={<CustomerWebsite />} />
          <Route path="/business/*" element={<BusinessWebsite />} />
          <Route path="/shop/:tagName" element={<TagProducts />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
