import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Navbar from './Navbar'; // Import the Navbar component
import { AiOutlineDollar } from 'react-icons/ai';
import { CiStar } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import Loader from '../common/Loader/Loader';
import { useSelector } from 'react-redux';
import {
  CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY,
  GET_PRODUCTS_BY_TAG,
} from '../GraphQL/mutation';
import client from '../GraphQL/ApolloClient';

const DEFAULT_LATITUDE = 24.850707597571677; // Default latitude
const DEFAULT_LONGITUDE = 67.2693574847137; // Default longitude

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [latitude, setLatitude] = useState(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState(DEFAULT_LONGITUDE);
  const [loading, setLoading] = useState(true);
  const [businessInfo, setBusinessInfo] = useState([]);
  const { productTags } = useSelector((state) => state.product);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          () => {
            // Fallback to default coordinates on error
            setLatitude(DEFAULT_LATITUDE);
            setLongitude(DEFAULT_LONGITUDE);
          },
        );
      } else {
        // Fallback if geolocation is not supported
        setLatitude(DEFAULT_LATITUDE);
        setLongitude(DEFAULT_LONGITUDE);
      }
    };

    getUserLocation();
  }, []);

  useEffect(() => {
    const fetchProductsByTag = async () => {
      const tags = productTags?.map((item) => item.tagId);
      try {
        const response = await client.query({
          query: GET_PRODUCTS_BY_TAG,
          variables: { tagIds: tags },
        });
        // Handle product data from response here if needed
      } catch (error) {
        console.error('Error fetching products by tag:', error);
      }
    };

    fetchProductsByTag();
  }, [productTags]);

  const fetchBusinessesWithinRange = async (latitude, longitude, pageNo, pageSize) => {
    setLoading(true);
    const variables = { latitude, longitude, pageNo, pageSize };

    try {
      console.log(`Fetching businesses with Latitude: ${latitude}, Longitude: ${longitude}`);
      const response = await client.query({
        query: CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY,
        variables,
      });
      setBusinessInfo(response?.data?.calculateBusinessesWithinRangeByLocation?.data?.businesses || []);
      
      const allProducts = response?.data?.calculateBusinessesWithinRangeByLocation?.data?.businesses?.flatMap((business) =>
        business?.businessDetails?.inventoryList?.flatMap((inventory) =>
          inventory.products.map((product) => ({
            ...product,
            businessId: business.businessId,
            category: inventory.category.categoryName,
          })),
        ),
      );

      setProducts(allProducts || []);
      setFilteredProducts(allProducts || []);

      // Log the products being rendered
      console.log('Products being rendered:', allProducts || []);
    } catch (error) {
      console.error('Error fetching businesses:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinessesWithinRange(latitude, longitude, pageNo, pageSize);
  }, [latitude, longitude, pageNo, pageSize]);

  const handleSearch = (value) => {
    setSearchValue(value);  // Update the search value state
    if (value === '') {
      setFilteredProducts(products);
      return;
    }

    const filtered = products.filter((product) =>
      product.productName.toLowerCase().includes(value.toLowerCase()) ||
      product.description.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  return (
    <>
      <Navbar /> {/* Include the Navbar component here */}
      <div className="font-poppins">
        <div className="container m-auto py-[6rem]">
          {/* Search Bar */}
          <div className="mb-6" style={{marginTop:'100px'}}>
            <input
              type="text"
              placeholder="Search by product name"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              className="border rounded p-2 w-full"
            />
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="prod-loop flex flex-wrap justify-center items-center">
              {filteredProducts.length === 0 ? (
                <div className="no-products-message text-center text-xl text-red-600">
                  No products available at this location.
                </div>
              ) : (
                filteredProducts.map((product, index) => (
                  <Link key={index} to={`/pp/${product.slug}`} className="prod-wrapper mx-4 mt-10 w-full xl:w-[23%] lg:w-[22%] md:w-[44%] sm:w-1/2 cursor-pointer">
                    <div className="img flex h-80 justify-center items-center text-center bg-[#EDEDED]">
                      <img style={{ maxHeight: 300 }} src={`https://storage.googleapis.com/awkat-o-salat/${product.listImagePath[0]}`} alt={product.description} />
                    </div>
                    <h4 className="prod-title text-[20px] italic font-semibold mt-3 text-[#273B60]">{product.productName}</h4>
                    <p className="prod-description text-[16px] text-[#273B60]">{product.description}</p>
                    <div className="prod-info flex justify-between items-center">
                      <div>
                        <div className="flex items-center">
                          <AiOutlineDollar className="text-lg text-[#273B60]" />
                          <h4 className="prod-title text-[20px] italic font-semibold mt-1 text-[#273B60] ml-2">{`$${product.discountedPrice}.00`}</h4>
                        </div>
                        <div className="flex">{[...Array(5)].map((_, i) => <CiStar key={i} className="text-xl text-[#FDCE0C]" />)}</div>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          )}
          {/* Pagination Controls */}
          <div className="flex justify-center mt-20">
            <button onClick={() => setPageNo(prev => Math.max(prev - 1, 0))} className="thm-btn py-2 px-4" disabled={pageNo === 0}>Previous</button>
            <button onClick={() => setPageNo(prev => prev + 1)} className="thm-btn py-2 px-4" disabled={filteredProducts.length < pageSize}>Next</button>
            <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize} className="ml-4 py-2 px-4 border rounded">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Products;
