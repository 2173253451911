import React, { useEffect, useState } from 'react';
import './ServiceTimeSlots.css'; // Ensure the CSS is correctly imported
import { useLazyQuery, useMutation } from '@apollo/client'; // Import Apollo Client hooks
import { GET_SLOTS_BY_BUSINESS_AND_SERVICE, CREATE_BOOKING_SERVICES } from '../GraphQL/mutation'; // Import your GraphQL query and mutation
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const ServiceTimeSlots = ({ service, businessId, onClose }) => {
  const [userId, setUserId] = useState('aaaaaaaaaaaaaaaaaaaaaaaa'); // Default value
  const [selectedSlot, setSelectedSlot] = useState(null); // To track the selected slot
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const imageBaseUrl = 'https://storage.googleapis.com/awkat-o-salat/';
  const imageUrl = service.listImagePath && service.listImagePath.length > 0
    ? `${imageBaseUrl}${service.listImagePath[0]}`
    : `${imageBaseUrl}default-image.jpg`;
// GraphQL Query to fetch slots by business and service
  const [getSlots, { loading: loadingSlots, error: errorSlots, data }] = useLazyQuery(GET_SLOTS_BY_BUSINESS_AND_SERVICE);

  // GraphQL Mutation to create booking service
  const [createBookingServices, { loading: loadingBooking, error: errorBooking }] = useMutation(CREATE_BOOKING_SERVICES, {
    onCompleted: (response) => {
      console.log('Booking response:', response);
      console.log('About to show success toast');
      toast.success('Booking made successfully!'); // Use Toastify for success
      onClose(); // Close the modal after successful booking
    },
    onError: (error) => {
      console.error('Booking error:', error); // Log the error
      toast.error(`Error: ${error.message || 'Unknown error occurred'}`); // Use Toastify for error
    },
  });

  // Function to get current date in "YYYY-MM-DD" format
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero to month if needed
    const day = String(today.getDate()).padStart(2, '0'); // Add leading zero to day if needed
    return `${year}-${month}-${day}`;
  }

 
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId); 
      console.log(storedUserId);
    }

    // Trigger the API call when component mounts
    if (businessId && service.serviceName) {
      getSlots({
        variables: {
          businessId,
          date: currentDate,
          serviceName: service.serviceName,
          customerId: userId,
        },
      });
    }
  }, [businessId, service.serviceName, userId, getSlots]);

  // Function to check if the time slot is in the past
  const isTimeInPast = (startTime) => {
    const currentTime = new Date();
    const slotStartTime = new Date(startTime);
    return slotStartTime < currentTime;
  };

  // Handle slot selection
  const handleSlotSelect = (index) => {
    const slot = slots[index];
    if (isTimeInPast(slot.timeObj.startTime)) {
      toast.error('You cannot select past time slots.'); // Use Toastify
    } else {
      setSelectedSlot(index); // Use index instead of slot number
    }
  };
  const handleBookNow = () => {
    if (selectedSlot === null) {
      toast.error('Please select a time slot.');
      return;
    }
  
    const selectedSlotData = slots[selectedSlot];
    console.log('Selected Slot Data:', selectedSlotData);
  
    const bookingInput = {
      businessId,
      slotNumber: selectedSlotData.slotNumber,
      date: currentDate,
      timeObj: {
        startTime: selectedSlotData.timeObj.startTime,
        endTime: selectedSlotData.timeObj.endTime,
      },
      totalAmount: 100,
      instructions: 'Good service dena',
      serviceCartItems: {
        serviceId: service.serviceId || ' ',
        serviceName: service.serviceName,
        price: service.price,
        discount: service.discount || 0,
        discountedPrice: service.discountedPrice || 0,
        listImagePath: imageUrl
      },
    };
  
    console.log('businessId:', businessId);
console.log('service:', service);

    const loadingToastId = toast.loading('Booking in progress... Please wait.');
  
    createBookingServices({
      variables: {
        userId,
        CreateBookingInput: bookingInput,
      },
      onCompleted: (response) => {
        console.log('Booking mutation completed:', response);
        if (response) {
          // Close the loading toast and show an alert for success
          toast.dismiss(loadingToastId);
          alert('Booking made successfully!');
        } else {
          toast.update(loadingToastId, {
            render: 'Booking failed. No response received.',
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        }
        onClose(); // Close the modal after showing the alert
      },
      onError: (error) => {
        console.error('Booking error:', error);
        toast.update(loadingToastId, {
          render: `Error: ${error.message || 'Unknown error occurred'}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      },
    });
  };
  
  
  // Loading and error states
  if (loadingSlots) return <p>Loading slots...</p>;
  if (errorSlots) return <p>Error fetching slots: {errorSlots.message}</p>;

  // Display the slots data if available
  const slots = data?.getSlotsByBusinessAndService?.data || [];

  return (
    <>
      <ToastContainer position="top-right" autoClose={27000} /> {/* Toast Container */}
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Select a Time Slot for {service.serviceName || 'Unnamed Service'}</h2>

          {/* Display the current date in YYYY-MM-DD format */}
          <p>Date: {currentDate}</p>

          {/* Display available slots */}
          <div className="slots-container">
            {slots.length > 0 ? (
              <div className="slots-list">
                {slots.map((slot, index) => (
                  <label key={slot.slotNumber} className={`slot-item ${isTimeInPast(slot.timeObj.startTime) ? 'past-slot' : ''}`}>
                    <input
                      type="radio"
                      name="timeSlot"
                      value={index} // Use index here
                      checked={selectedSlot === index} // Compare with index
                      onChange={() => handleSlotSelect(index)} // Pass index to the handler
                    />
                    <span className="slot-details">
                      Slot {slot.slotNumber} - {new Date(slot.timeObj.startTime).toLocaleTimeString()} to{' '}
                      {new Date(slot.timeObj.endTime).toLocaleTimeString()} - Available: {slot.totalAvailable}, Booked: {slot.totalBooked}
                    </span>
                  </label>
                ))}
              </div>
            ) : (
              <p>No slots available for the selected date and service.</p>
            )}
          </div>

          {/* Close Button */}
          <button className="close-btn" onClick={onClose}>
            Close
          </button>

          {/* Book Now Button */}
          <button className="book-now-btn" onClick={handleBookNow}>
            Book Now
          </button>
        </div>
      </div>
    </>
  );
};

export default ServiceTimeSlots;
