import React from 'react';
import { FaUser } from 'react-icons/fa'; // Importing the user icon
import './UpdateProfile.css'; // Importing the styles

const UpdateProfile = () => {
  return (
    <div className="profile-container">
      <h2 className="profile-header">Profile</h2>

      <div className="profile-picture">
        <div className="avatar">
          <FaUser size={20} color="#333" />
        </div>
      </div>
      
      <form className="profile-form">
        <div className="form-group">
          <label htmlFor="firstName">First Name *</label>
          <input type="text" id="firstName" placeholder="First Name" />
        </div>
        
        <div className="form-group">
          <label htmlFor="lastName">Last Name *</label>
          <input type="text" id="lastName" placeholder="Last Name" />
        </div>
        
        <div className="form-group">
          <label htmlFor="username">Username/Email *</label>
          <input type="text" id="username" placeholder="Username/Email" />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input type="email" id="email" placeholder="Email" />
        </div>
        
        <a href="/change-password" className="change-password-link">Change Password</a>
        
        <button type="submit" className="update-button">Update</button>
      </form>
    </div>
  );
};

export default UpdateProfile;
