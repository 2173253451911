import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($userData: UserInput) {
    createUser(input: $userData) {
      message
      code
      status
      data {
        userId
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($newPassword: String!, $user: String!) {
    forgotPassword(newPassword: $newPassword, user: $user) {
      status
    }
  }
`;

export const GET_REPORTS = gql`
  query GetReports(
    $metrics: [String!]!
    $dimensions: [String!]!
    $reportType: String!
    $propertyId: String!
    $startDate: String!
    $endDate: String!
  ) {
    getReports(
      data: {
        metrics: $metrics
        dimensions: $dimensions
        reportType: $reportType
        propertyId: $propertyId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      message
      code
      status
      data
    }
  }
`;

// Define the query to fetch business data for the user
export const GET_BUSINESS_BY_USER = gql`
  query {
    getBusinessByUser {
      message
      code
      status
      data {
        businessId
        isOpened
        businessInfo {
          propertyId
        }
      }
    }
  }
`;

export const GET_TASKS_BY_ID = gql`
  query GetTasksById($taskId: String!) {
    getTasksById(taskId: $taskId) {
      message
      code
      status
      data
    }
  }
`;

export const GET_PRODUCT_BY_IDENTIFIER = gql`
  query GetProductByIdentifier($identifier: String!) {
    getProductByIdentifier(identifier: $identifier) {
      status
      code
      message
      data {
        productName
        description
        quantity
        status
        price
        discount
        discountedPrice
        likedCount
        purchasedCount
        imagePaths
        listImagePath
        createdAt
        lastModifiedAt
        category {
          categoryName
        }
        tags {
          name
          category
        }

        business {
          createdAt
          lastModifiedAt
          isOpened
          isClaim
          timings {
            timings {
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
              sunday {
                startTime
                endTime
              }
            }
          }
          businessInfo {
            name
            imagePath
            listImagePath
            ntn
            salesTax
            currency
            businessTypes
            address {
              street
              postalCode
              additionalAddress
              residence
              area
              city
              province
              country
              countryCode
              latitude
              longitude
              formatedAddress
              addressName
              addressType
            }
          }
        }
      }
    }
  }
`;

export const CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY = gql`
  query CalculateBusinessesWithinRangeByLocation(
    $latitude: Float!
    $longitude: Float!
    $pageNo: Int!
    $pageSize: Int!
  ) {
    calculateBusinessesWithinRangeByLocation(
      latitude: $latitude
      longitude: $longitude
      pageNo: $pageNo
      pageSize: $pageSize
    ) {
      message
      code
      status
      data {
        businesses {
          businessId
          isOpened
          isClaim
          businessDetails {
            inventoryList {
              products {
                productName
                slug
                description
                quantity
                status
                price
                discount
                discountedPrice
                likedCount
                purchasedCount
                imagePaths
                listImagePath
              }
              category {
                categoryName
              }
            }

            servicesList {
            serviceId
              serviceName
              imagePaths
              listImagePath
              description
              status
              price
              availableSlots
              discount
              discountedPrice
              likedCount
              purchasedCount
            }
          }
          businessInfo {
            name
            imagePath
            listImagePath
            address {
              street
              postalCode
              additionalAddress
              formatedAddress
              addressName
              residence
              area
              city
              province
              country
              latitude
              longitude
              addressType
              countryCode
            }
            currency
          }
          timings {
            timings {
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
              sunday {
                startTime
                endTime
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TAGS_DATA = gql`
  query {
    getTagsData {
      status
      code
      message
      data {
        tagId
        name
        category
      }
    }
  }
`;



export const GET_PRODUCTS_BY_TAG = gql`
  query GetProductsByTag($tagIds: [String!]!) {
    getProductsByTag(tagIds: $tagIds) {
      status
      code
      message
      data {
        tagName
        products {
          productId
          productName
          slug
          description
          quantity
          status
          price
          discount
          discountedPrice
          likedCount
          purchasedCount
          imagePaths
          listImagePath
          createdAt
          lastModifiedAt
          category {
            categoryName
          }
          tags {
            name
            category
          }
          business {
            createdAt
            lastModifiedAt
            isOpened
            isClaim
            timings {
              timings {
                monday {
                  startTime
                  endTime
                }
                tuesday {
                  startTime
                  endTime
                }
                wednesday {
                  startTime
                  endTime
                }
                thursday {
                  startTime
                  endTime
                }
                friday {
                  startTime
                  endTime
                }
                saturday {
                  startTime
                  endTime
                }
                sunday {
                  startTime
                  endTime
                }
              }
            }
            businessInfo {
              name
              imagePath
              listImagePath
              ntn
              salesTax
              currency
              businessTypes
              currency
              address {
                street
                postalCode
                additionalAddress
                residence
                area
                city
                province
                country
                countryCode
                latitude
                longitude
                formatedAddress
                addressName
                addressType
              }
            }
          }
        }
      }
    }
  }
`;


export const CREATE_ORDER = gql`
  mutation createOrder($createOrderInput: CreateOrderInput, $timeObj: TimeObjInput) {
    createOrder(input: $createOrderInput, timeObj: $timeObj) {
      message
      status
      data {
        products {
          productName
          quantity
          price
          discount
          discountedPrice
          errorType
          updatedStock
          imagePaths
          listImagePath
          previousQuantity
        }
      }
    }
  }
`;



// Query for getting orders
export const GET_ORDERS_BY_CUSTOMER_AND_STATUS = gql`
  query getOrdersByCustomerAndStatus($customerId: String!, $status: String!, $pageNo: Int!, $noOfElements: Int!) {
    getOrdersByCustomerAndStatus(customerId: $customerId, status: $status, pageNo: $pageNo, noOfElements: $noOfElements) {
      data {
        orderId
        status
        customer {
          name {
            firstName
            lastName
          }
        }
        business {
          businessInfo {
            name
            currency
          }
        }
        orderDetails {
          totalAmount
          productCartItems {
            productName
            quantity
            price
          }
        }
      }
      totalPages
    }
  }
`;

// Query for getting bookings
export const GET_BOOKINGS_BY_CUSTOMER_ID_AND_STATUS = gql`
 query getBookingsByCustomerIdAndStatus($customerId: String!, $statusType: String!, $pageNo: Int!, $noOfElements: Int!) {
  getBookingsByCustomerIdAndStatus(customerId: $customerId, statusType: $statusType, pageNo: $pageNo, noOfElements: $noOfElements) {
    data {
      bookingId
      status
      business {
        businessInfo {
          name
        }
      }
      bookingDetails {
        serviceCartItems {
          serviceId # Add this if you need service ID
          serviceName
          price
          discount # Add this if you need discount
          discountedPrice # Add this if you need discounted price
        }
           timeObj {
          startTime
          endTime
        }
          slotNumber
        totalAmount
      }
    }
    totalPages
  }
}

`;
// service booking time slots get.
export const GET_SLOTS_BY_BUSINESS_AND_SERVICE = gql`
  query getSlotsByBusinessAndService(
    $businessId: String!,
    $date: String!,
    $serviceName: String!,
    $customerId: String!
  ) {
    getSlotsByBusinessAndService(businessId: $businessId, date: $date, serviceName: $serviceName, customerId: $customerId) {
      data {
        slotNumber
        timeObj {
          startTime
          endTime
        }
        totalAvailable
        totalBooked
        bookedByUser
      }
    }
  }
`;
// Mutation for creating a booking service
export const CREATE_BOOKING_SERVICES = gql`
  mutation createBookingServices($userId: String!, $CreateBookingInput: CreateBookingInput!) {
    createBookingServices(userId: $userId, input: $CreateBookingInput) {
      data {
        bookingId
        status
      }
      status
      message
    }
  }
`;






// Query for fetching services based on search name
export const GET_SERVICES_GENERIC_SEARCH_KEY_AUTH = gql`
  query getServicesGenericSearch(
    $searchName: String!,
    $timeObj: TimeObjInput,
    $pageNo: Int!,
    $pageSize: Int!
  ) {
    getServicesGenericSearch(
      searchName: $searchName,
      timeObj: $timeObj,
      pageNo: $pageNo,
      pageSize: $pageSize
    ) {
      data {
        products {
          product {
            productName
          }
          business {
            businessId
          }
          category {
            categoryName
          }
        }
        serviceList {
          service {
            serviceName
            imagePaths
            listImagePath
            description
            status
            price
            discountedPrice
            availableSlots
            discount
            likedCount
            purchasedCount
          }
          business {
            businessId
            businessInfo {
              name
              currency
              address {
                street
                postalCode
                additionalAddress
                residence
                area
                city
                province
                country
                latitude
                longitude
                formatedAddress
                addressName
                addressType
              }
              imagePath
              listImagePath
            }
            timings {
              timings {
                monday {
                  startTime
                  endTime
                }
                tuesday {
                  startTime
                  endTime
                }
                wednesday {
                  startTime
                  endTime
                }
                thursday {
                  startTime
                  endTime
                }
                friday {
                  startTime
                  endTime
                }
                saturday {
                  startTime
                  endTime
                }
                sunday {
                  startTime
                  endTime
                }
              }
            }
          }
          
        }
        businesses {
          businessId
          businessInfo {
            name
            currency
            address {
              street
              postalCode
              additionalAddress
              residence
              area
              city
              province
              country
              latitude
              longitude
              formatedAddress
              addressName
              addressType
            }
            imagePath
            listImagePath
          }
          timings {
            timings {
              monday {
                startTime
                endTime
              }
              tuesday {
                startTime
                endTime
              }
              wednesday {
                startTime
                endTime
              }
              thursday {
                startTime
                endTime
              }
              friday {
                startTime
                endTime
              }
              saturday {
                startTime
                endTime
              }
              sunday {
                startTime
                endTime
              }
            }
          }
         
        }
      }
    }
  }
`;


export const GET_PRODUCTS_BY_NAME_LOCATION = gql`
  query GetProductsGenericSearchKeyAuth(
    $searchName: String!
    $timeObj: TimeObject!
    $latitude: Float!
    $longitude: Float!
    $pageNo: Int!
    $pageSize: Int!
  ) {
    getProductsGenericSearchKeyAuth(
      searchName: $searchName
      timeObj: $timeObj
      latitude: $latitude
      longitude: $longitude
      pageNo: $pageNo
      pageSize: $pageSize
    ) {
      message
      code
      status
      data {
        serviceList {
          service {
            serviceName
          }
        }
        products {
          product {
            productName
            description
            quantity
            status
            price
            discount
            discountedPrice
            likedCount
            purchasedCount
            imagePaths
            listImagePath
          }
          business {
            businessId
            businessInfo {
              name
              currency
              address {
                street
                postalCode
                additionalAddress
                residence
                area
                city
                province
                country
                latitude
                longitude
                formattedAddress
                addressName
                addressType
              }
              imagePath
              listImagePath
            }
            timings {
              timings {
                monday { startTime endTime }
                tuesday { startTime endTime }
                wednesday { startTime endTime }
                thursday { startTime endTime }
                friday { startTime endTime }
                saturday { startTime endTime }
                sunday { startTime endTime }
              }
            }
            isOpened
          }
          category {
            categoryName
          }
        }
        businesses {
          businessId
          businessInfo {
            name
            currency
            address {
              street
              postalCode
              additionalAddress
              residence
              area
              city
              province
              country
              latitude
              longitude
              formattedAddress
              addressName
              addressType
            }
            imagePath
            listImagePath
          }
          timings {
            timings {
              monday { startTime endTime }
              tuesday { startTime endTime }
              wednesday { startTime endTime }
              thursday { startTime endTime }
              friday { startTime endTime }
              saturday { startTime endTime }
              sunday { startTime endTime }
            }
          }
          isOpened
        }
      }
    }
  }
`;



export const GET_USER_BY_ID = gql`
  query getUserById {
    getUserById {
      userId
      userName
    }
  }
`;