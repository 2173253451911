import React, { useState, useEffect } from 'react';
import './style.css';
import { useQuery } from '@apollo/client';
import Navbar from '../../components/Navbar';
import { CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY } from '../../GraphQL/mutation'; 
import ServiceCard from '../../components/ServiceCard';

const Index = () => {
  const [latitude, setLatitude] = useState(24.850707597571677); // Default latitude
  const [longitude, setLongitude] = useState(67.2693574847137); // Default longitude
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [searchName, setSearchName] = useState(''); // State to track search input

  // Fetch user's geolocation when the component mounts
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationLoaded(true);
        },
        (error) => {
          console.log("Error fetching location: ", error.message);
          setLocationLoaded(true); // Proceed with default coordinates
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLocationLoaded(true); // Proceed with default coordinates
    }
  }, []);

  // Apollo useQuery for fetching businesses data
  const { loading, error, data } = useQuery(CALCULATE_BUSINESSES_WITHIN_RANGE_QUERY, {
    variables: {
      latitude,
      longitude,
      pageNo: 0,
      pageSize: 10,
    },
    skip: !locationLoaded,
    onCompleted: () => {
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }
  });

  // Extract businesses from the query response
  const businesses = data?.calculateBusinessesWithinRangeByLocation?.data?.businesses || [];

  // Function to handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchName(value);
    console.log('Search value:', value); // Debugging to ensure search input updates
  };

  return (
    <div className="font-poppins">
      <Navbar showSearch={false} />

      {/* Search Bar */}
      <div className="search-bar" style={{ marginTop: '200px' }}>
        <input
          type="text"
          placeholder="Search by service name"
          value={searchName}
          onChange={handleSearchChange}
        />
      </div>

      <div className="services-container">
        {loading && <p>Loading businesses...</p>}
        {error && <p>Error: {error.message}</p>}

        {/* Display filtered businesses that have matching services */}
        {businesses.length > 0 ? (
          businesses
            .map((business, index) => {
              const { businessInfo, businessDetails } = business;
              const services = businessDetails.servicesList || [];

              // Filter services based on search name
              const filteredServices = services.filter(service => 
                service.serviceName.toLowerCase().includes(searchName.toLowerCase())
              );

              // Only return the business if it has matching services
              if (filteredServices.length > 0) {
                return (
                  <div key={index} className="business-section">
                    <h2>Business: {businessInfo.name}</h2>
                    <p><b>Address:</b> {businessInfo.address.formatedAddress}</p>

                    <div className="services-list">
                      {filteredServices.map((service, serviceIndex) => (
                        <ServiceCard key={serviceIndex} service={service} businessId={business.businessId} />
                      ))}
                    </div>
                  </div>
                );
              }
              return null; // Do not render the business if no services match
            })
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default Index;
