import {
  FaPlus,
  FaMinus,
  FaArrowLeft,
  FaArrowRight,
  FaCheck,
  FaTruck,
  FaStar,
  FaQuoteLeft,
  FaCar,
} from 'react-icons/fa';
import 'tailwindcss/tailwind.css';
import { FaTicketAlt } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addItemToCart } from '../store/reducers/cart';
import { useParams } from 'react-router-dom';

const ProductDetails = ({ productData }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const { slug } = useParams();

  const [showDetails, setShowDetails] = useState(false); // State for showing more details

  const dispatch = useDispatch();

  const addToCartHandler = (product) => {
    dispatch(
      addItemToCart({
        id: slug,
        name: product?.productName,
        price: product?.price,
        quantity: quantity,
        imageName: product?.listImagePath[0],
      }),
    );
  };

  const handleQuantityChange = (change) => {
    setQuantity((prev) => Math.max(1, prev + change));
  };

  const handleImageChange = (index) => {
    setSelectedImage(index);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const businessInfo = productData?.business?.businessInfo;
  const businessTiming = productData?.business?.timings?.timings;
  const images = productData?.listImagePath?.map(
    (imageName) => `https://storage.googleapis.com/awkat-o-salat/${imageName}`,
  );
  const businessImage = businessInfo?.listImagePath?.map(
    (imageName) => `https://storage.googleapis.com/awkat-o-salat/${imageName}`,
  );

  const nextImage = () => {
    setSelectedImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedImage(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  }
  useEffect(() => {
    
    const today = new Date();

    // Calculate delivery date after 5 days
    const deliveryDate = new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000);

    // Format delivery date as "DD Mon - DD Mon"
    const deliveryDateString = `${formatDate(today)} - ${formatDate(
      deliveryDate,
    )}`;

    // Update state with formatted delivery date
    setDeliveryDate(deliveryDateString);
  }, []);
  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto py-16">
      <nav
        aria-label="breadcrumb"
        class="w-full bg-white mb-3 rounded shadow-md"
      >
        <ol class="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
          <li class="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
            <a href="#" class="opacity-60">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </a>
            <span class="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
              /
            </span>
          </li>
          <li class="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
            <a href="#" class="opacity-60">
              <span className="font-poppins">Components</span>
            </a>
            <span class="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
              /
            </span>
          </li>
         
        </ol>
      </nav>
      <div className="bg-white shadow-md border-t border-gray-100 rounded-lg overflow-hidden mb-4">
        <h2 className="text-2xl font-semibold text-gray-800 p-4 border-b border-gray-300">
          Product Details
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="p-4 flex flex-col items-start">
            <div className="relative xl:full mb-4">
              <img
                src={images[selectedImage]}
                alt="Product"
                className="w-full rounded-lg mr-4"
              />
              <button
                onClick={prevImage}
                className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2"
              >
                <FaArrowLeft />
              </button>
              <button
                onClick={nextImage}
                className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2"
              >
                <FaArrowRight />
              </button>
            </div>
            <div className="flex xl:full ">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`thumbnail ${index + 1}`}
                  className={`w-12 h-12 mx-1 cursor-pointer ${
                    selectedImage === index
                      ? 'border-2 border-blue-600'
                      : 'border border-gray-300'
                  }`}
                  onClick={() => handleImageChange(index)}
                />
              ))}
            </div>
          </div>
          <div className="p-4">
            <h5 className="text-xl font-semibold mb-2">
              {productData.productName}
            </h5>
            <div class="flex items-center underline cursor-pointer mb-2">
              <svg
                class="w-4 h-4 text-yellow-300 me-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg
                class="w-4 h-4 text-yellow-300 me-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg
                class="w-4 h-4 text-yellow-300 me-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg
                class="w-4 h-4 text-yellow-300 me-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <svg
                class="w-4 h-4 text-gray-300 me-1 dark:text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
              >
                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
              </svg>
              <p class="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                4.95
              </p>
              <p class="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                out of
              </p>
              <p class="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                5
              </p>
            </div>
            {productData.status === 'AVAILABLE' ? (
              <span className="inline-flex my-3 items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                <FaCheck /> &nbsp;In Stock
              </span>
            ) : (
              <span className="inline-flex my-3 items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                <span className="font-bold">X</span> &nbsp;Out Of Stock
              </span>
            )}

            <p className="text-gray-600 mb-4">{productData.description}</p>
            <p className="text-gray-600 mb-4">
              Category: {productData?.category?.categoryName}
            </p>
            <h6 className="text-lg font-semibold mt-4">
              Price : ${productData.price}
            </h6>
            {/* <h4 className="text-red-500 text-2xl mt-2">
              Rs. {productData.discountedPrice}
            </h4> */}
            <p className="text-gray-600 mt-1">
              {/* <strike>${productData.price}</strike> -{' '} */}
            </p>
           
            <div className="color-option flex items-center mt-4 mb-4">
            

            </div>
            <div className="flex items-center mt-3">
              <p className="mr-2">Quantity</p>
              <div class="relative flex items-center max-w-[8rem]">
                <button
                  type="button"
                  id="decrement-button"
                  onClick={() => handleQuantityChange(-1)}
                  data-input-counter-decrement="quantity-input"
                  class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
                >
                  <FaMinus />
                </button>
                <input
                  type="text"
                  id="quantity-input"
                  data-input-counter
                  value={quantity}
                  data-input-counter-min="1"
                  data-input-counter-max="50"
                  aria-describedby="helper-text-explanation"
                  class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 "
                  placeholder="999"
                  required
                />
                <button
                  type="button"
                  id="increment-button"
                  data-input-counter-increment="quantity-input"
                  onClick={() => handleQuantityChange(1)}
                  class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
                >
                  <FaPlus />
                </button>
              </div>
            </div>

            <div className="cta-btns flex justify-start gap-6 mt-4">
              <button className="text-[12px] py-2 px-4 sm:py-2 sm:text-[12px] sm:px-3  xl:text-[20px]  lg:text-[16px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3 transition-all duration-300 theme-buybtn font-bold text-sm relative bg-[#273B60] rounded-lg btn xl:py-3 xl:px-7 text-white">
                Buy Now
              </button>
              <button
                onClick={() => addToCartHandler(productData)}
                className="text-[12px] py-2 px-4  sm:py-2 sm:text-[12px] sm:px-3 xl:text-[20px] lg:text-[16px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3  theme-cartbtn font-bold text-sm relative bg-[#D1822D] rounded-lg btn xl:py-3 xl:px-7 text-white"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 my-9 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 grid-rows-1">
        {/* <div className="">
          <div className="flex items-center">
            <span className="bg-green-700 p-3 rounded-full mr-3">
              <FaCheck color="white" size={24} />
            </span>
            <div className="flex flex-col">
              <h4 className="font-bold text-2xl">100% Guranteed</h4>
              <p className="text-gray-500">dsasadsad</p>
            </div>
          </div>
        </div> */}
        <div className="">
          <div className="flex items-center">
            <span className="bg-black p-3 rounded-full mr-3">
              <FaTruck color="white" size={24} />
            </span>
            <div className="flex flex-col">
              <h4 className="font-bold text-2xl">Fast & Free Shipping</h4>
              <ul className="list-none mt-2">
                <li className="text-sm">
                  Cutoff time: 17:00 (GMT-05:00) Eastern Standard Time (New
                  York)
                </li>
                <li className="text-sm">
                  Order processing time: 1-3 days (Mon-Fri)
                </li>
                <li className="text-sm">Delivery time: 4-8 days (Mon-Fri)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="featured-review mb-4">
        {/* <h2 className="text-2xl font-semibold text-gray-800 my-4">
          Featured Review
        </h2> */}

        <div className="review grid grid-cols-12 gap-4 mt-3">
          {/* <div className="col-span-3 flex items-start justify-between">
            <FaStar color="#FDE047" size={80} />
            <FaQuoteLeft size={30} />
          </div> */}
          {/* <div className="col-span-9">
            <span className="flex items-baseline mr-2">
              <p className="italic flex items-end justify-start">
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum
                deserunt ad optio, velit ullam ipsam omnis consequuntur atque.
                Animi blanditiis illo reiciendis culpa fugit nobis in similique
                perspiciatis eos accusantium."
              </p>
            </span>
          </div> */}
          {/* </div> */}
         
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4 pb-10">
        <div className="bg-[#F6F6F6] rounded-lg shadow-md p-4 pl-6">
          <h4 className="text-[#949494] xl:text-base">Delivery</h4>
          <div className="flex justify-between">
            <span className="address w-[60%]">
              {businessInfo.address.formatedAddress}
            </span>
            <span className="address-change">
              <button className="text-base cursor-pointer font-semibold text-[#273B60]">
                CHANGE
              </button>
            </span>
          </div>
          <hr className="my-3 bdr-thick" />
          <div className="flex justify-between">
            <div>
              <h4 className="xl:text-base">
                <span className="font-bold">Free Delivery</span> {deliveryDate}
                <span className="text-[#949494] mt-3 block xl:text-base">
                  5 - 10 day(s)
                </span>
              </h4>
            </div>
            <h4 className="text-base">Free </h4>
          </div>
          <hr className="my-3 bdr-thick" />
          <h4 className=" xl:text-base">
            <b>Payment:</b> Cash on Delivery Available
          </h4>
          <hr className="my-3 bdr-thick" />
          <div className="flex flex-col">
            <p className="text-[#949494] mt-3 block xl:text-base font-bold">
              Services
            </p>
            <p className="text-sm">14 days free & easy return</p>
            <a
              href="/customer/return-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              Return Policy
            </a>
            <p className="text-[#949494] block xl:text-base mt-2">
              <span className="font-bold">Important:</span> Change of mind is
              not acceptable
            </p>
          </div>
          <p className="mt-3">Warranty not available</p>
        </div>

        <div className="bg-[#F6F6F6] rounded-lg shadow-md flex flex-col justify-between ">
          <div className="p-4 pl-6">
            <h4 className="text-[#949494]  xl:text-base">Delivery</h4>
            <div className="flex justify-between">
              <span className="address w-[60%] font-semibold text-lg  store-owner">
                {businessInfo?.name}
              </span>
              <span className="address-change">
                <button className="text-base font-semibold cursor-pointer text-[#273B60]">
                  CHAT
                </button>
              </span>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="border-r w-full border-b text-center border-t p-5">
              <p className="text-[#949494]  xl:text-sm lg:text-xs">
                Positive Seller Ratings1
              </p>
              <h4 className="text-black font-semibold xl:text-2xl">70%</h4>
            </div>
            <div className="border-r w-full border-b text-center border-t p-5">
              <p className="text-[#949494]  xl:text-sm lg:text-xs">
                Ship OnTime
              </p>
              <h4 className="text-black font-semibold xl:text-2xl">86%</h4>
            </div>
            <div className="border-r w-full border-b text-center border-t p-5">
              <p className="text-[#949494]  xl:text-sm lg:text-xs">
                Chat Response Time
              </p>
              <h4 className="text-black font-semibold xl:text-2xl">96%</h4>
            </div>
          </div>
          <div className="flex  justify-between py-4 px-6">
            <span className="underline xl:text-lg cursor-pointer flex items-center font-semibold text-[#273B60]">
              VISIT STORE
            </span>
            <div className="flex justify-between flex-col items-center ">
              <p className="text-gray-600 flex items-center">
                <FaTicketAlt
                  className={`mr-2 ${businessInfo?.isOpened ? 'text-green-500' : 'text-red-500'}`}
                />
                <span className="font-semibold">Open Status:</span>{' '}
                {businessInfo?.isOpened ? (
                  <span className="text-green-500 ml-2"> Opened</span>
                ) : (
                  <span className="text-red-500 ml-2"> Closed</span>
                )}
              </p>
              <br />
              <button
                className="underline xl:text-lg cursor-pointer flex items-center uppercase font-semibold text-[#273B60]"
                onClick={() => setShowDetails(true)}
              >
                More Details
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Detailed Store Information (conditionally rendered) */}
      {showDetails && (
        <div className="bg-[#F6F6F6] shadow-md rounded-lg p-4 mt-4">
          <h3 className="text-lg font-semibold mb-2">More Store Details</h3>
          <div className="business-main xl:grid xl:grid-cols-2 lg:grid lg:grid-cols-2 md:grid-cols-2 gap-4">
            <div className="flex mb-4">
              <img
                src={businessImage}
                alt="Business"
                className=" xl:h-auto xl:w-auto lg:h-[70%] rounded-lg mr-4"
              />
            </div>

            <div className="business-div">
              <p className="text-gray-600">
                <span className="font-semibold ">Business Types:</span>{' '}
                {businessInfo?.businessTypes.join(', ')}
              </p>
              {businessInfo?.ntn && (
                <div className="mt-2">
                  <p className="text-gray-600 font-semibold">NTN:</p>
                  <p className="text-gray-600">{businessInfo?.ntn}</p>
                </div>
              )}
              <div className="mt-2">
                <p className="text-gray-600 font-semibold mb-3">
                  Store Timings:
                </p>
                <div className="grid xl:grid-cols-3 lg:grid-cols-2 xl:grid-rows-4 lg:grid-rows-3 md:grid-cols-3 md:grid-rows-3 sm:grid-cols-3 sm:grid-rows-3 gap-4">
                  {Object.keys(businessTiming)?.map(
                    (day, index) =>
                      day !== '__typename' && (
                        <div
                          key={index}
                          className="flex-1 max-w-xs border border-gray-300 rounded-lg p-4"
                        >
                          <p className="text-lg font-semibold mb-2 uppercase">
                            {day}
                          </p>
                          <div className="flex items-center md:flex-col md:items-start text-gray-600">
                            <p className="mr-4">
                              <span className="font-semibold">Open:</span>{' '}
                              {formatTime(businessTiming[day][0]?.startTime)}
                            </p>
                            <p>
                              <span className="font-semibold">Closed:</span>{' '}
                              {formatTime(businessTiming[day][0]?.endTime)}
                            </p>
                          </div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-2">
            <p className="text-gray-600 font-semibold">
              Sales Tax Registration:
            </p>
            <p className="text-gray-600">{businessInfo.salesTax}</p>
          </div> */}

          <button
            onClick={() => setShowDetails(false)}
            className="text-[12px] py-2 px-4 sm:py-2 sm:text-[12px] sm:px-3  xl:text-[20px]  lg:text-[16px] md:px-5 md:py-2 md:font-semibold  lg:px-7 lg:py-3 transition-all duration-300 theme-buybtn font-bold text-sm relative bg-[#273B60] rounded-lg btn xl:py-3 xl:px-7 text-white"
          >
            Show Less
          </button>
        </div>
      )}

      {/* <div className="accordion">
      {[
        { title: 'Customer Reviews', content: 'Content for Customer Reviews' },
        { title: 'Customer Questions and Answers', content: 'Content for Customer Questions and Answers' },
        { title: 'Features and Specs', content: 'Content for Features and Specs' },
        { title: 'Shipping and Return Policy', content: 'Content for Shipping and Return Policy' }
      ].map((section, index) => (
        <div key={index} className="relative mb-3">
          <h6 className="mb-0">
            <button
              className="relative flex items-center w-full p-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
              onClick={() => toggleAccordion(index)}
            >
              <span>{section.title}</span>
             {activeIndex === index ? <FaMinus  color={'#000'} size={20} className="absolute right-0 pt-1" />  : <FaPlus color={'#000'} size={20} className="absolute right-0 pt-1"  />}
            </button>
          </h6>
          <div
            className={`transition-all duration-300 ease-in-out ${activeIndex === index ? 'h-auto' : 'h-0 overflow-hidden'}`}
          >
            <div className="p-4  text-sm leading-normal text-blue-gray-500/80">
              {section.content}
            </div>
          </div>
        </div>
      ))}
    </div> */}
    </div>
  );
};

export default ProductDetails;
