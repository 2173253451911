import React, { useState, useEffect } from 'react';
import Logo from '../assests/tajam-logo.svg';
import {
  FaTimes,
  FaUser,
} from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { navLinks } from '../constants/navData';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import HamburgerMenu from './HamburgerMenu';
import HistoryModal from './HistoryModal';
import { GET_USER_BY_ID } from '../GraphQL/mutation';
import { useLazyQuery } from '@apollo/client';

const Navbar = ({ showSearch, searchQuery, handleSearch, setSearchQuery }) => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [username, setUsername] = useState('');
  const cartItems = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  const [getUserById, { data: userData }] = useLazyQuery(GET_USER_BY_ID);

  // Fetch username on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getUserById();
  }, [getUserById]);

  // Set username from API response
  useEffect(() => {
    if (userData) {
      const fetchedUsername = userData.getUserById?.userName?.split('@')[0] || '';
      setUsername(fetchedUsername);
    }
  }, [userData]);

  const handleLogout = () => {
    localStorage.clear();
    logout();
    navigate('/');
  };

  return (
    <div className="w-full pb-10 relative">
      {showHamburgerMenu && (
        <HamburgerMenu setShowHamburgerMenu={setShowHamburgerMenu} />
      )}

      <div className="flex fixed top-0 justify-center items-center w-full z-50 flex-col">
        <div className="w-full shadow-navbarShadow bg-white flex justify-between items-center px-2 py-2 gap-x-2">
          {/* Logo */}
          <a href="/">
            <img src={Logo} alt="Logo" className="lg:h-20 h-8 lg:w-54" />
          </a>

          {/* Search Bar */}
          {showSearch && (
            <div className="relative flex items-center w-full max-w-md">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  handleSearch(e.target.value);
                }}
                className="border border-gray-300 rounded-md py-1 px-2 text-sm w-full focus:outline-none focus:ring-2 focus:ring-[#F16427]"
                placeholder="Search..."
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute right-2 text-gray-500 hover:text-gray-700"
                >
                  <FaTimes size={20} />
                </button>
              )}
            </div>
          )}

          {/* NAVLINKS SECTION */}
          <div className="sm:flex hidden gap-x-3 items-center p-0 bg-none">
            {navLinks?.map((item, index) => (
              <a href={item.link} key={index}>
                <p className="text-[#313131] hover:text-[#6d96e0] transition-all ease-in-out text-sm lg:text-base font-poppins cursor-pointer flex items-center">
                  {item?.title}
                  {item.title === 'Cart' && cartItems?.length > 0 && (
                    <span className="ml-1 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                      {cartItems?.length}
                    </span>
                  )}
                </p>
              </a>
            ))}
          </div>

          {/* User Info Section */}
          {!isAuthenticated ? (
            <button
              onClick={() => navigate('/login')}
              className="text-[#F16427] text-xs px-2 py-1 border border-[#F16427] rounded-md shadow-md hover:bg-[#F16427] hover:text-white"
            >
              Login
            </button>
          ) : (
            <div className="flex items-center gap-4">
              {/* Avatar Icon with Username */}
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => navigate('/updateprofile')} // Navigate to UpdateProfile
              >
                <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center">
                  <FaUser size={20} color="#333" />
                </div>
                <span className="text-xs mt-1 font-medium">{username}</span>
              </div>

              <button
                onClick={() => setShowHistoryModal(true)} // Show the History modal
                className="text-[#F16427] text-xs px-2 py-1 bg-white border border-[#F16427] rounded-md shadow-md hover:bg-[#F16427] hover:text-white focus:outline-none mb-1 sm:text-sm sm:px-4 sm:py-2"
              >
                History
              </button>
              <button
                onClick={handleLogout}
                className="text-[#F16427] text-xs px-2 py-1 bg-white border border-[#F16427] rounded-md shadow-md hover:bg-[#F16427] hover:text-white focus:outline-none mb-1 sm:text-sm sm:px-4 sm:py-2"
              >
                Logout
              </button>
            </div>
          )}

          {/* Hamburger Menu Icon */}
          <GiHamburgerMenu
            size={25}
            className="text-[#F16427] cursor-pointer lg:hidden"
            onClick={() => setShowHamburgerMenu(true)}
          />
        </div>
      </div>

      {/* RESPONSIVE NAVLINK SECTION */}
      {showHamburgerMenu && (
        <div className="absolute right-0 top-2 block sm:hidden w-full pt-5 pb-5 pl-3 bg-white">
          {navLinks?.map((item, index) => (
            <a href={item.link} key={index}>
              <p className="text-[#313131] mb-2 text-sm font-poppins cursor-pointer">
                {item?.title}
              </p>
            </a>
          ))}
        </div>
      )}

      {/* History Modal */}
      <HistoryModal
        isOpen={showHistoryModal}
        onClose={() => setShowHistoryModal(false)}
      />
    </div>
  );
};

export default Navbar;
