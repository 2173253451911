// user.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  userInfo: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthorizedHandler: (state, action) => {
      localStorage.setItem('isAuthenticated', action.payload);
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    setUserInfo: (state, action) => {
      localStorage.setItem('token', action.payload.access_token);
      console.log('User Info:', action.payload); // Console log to see the structure of userInfo
      return {
        ...state,
        userInfo: action.payload,
      };
    },
  },
});

export const { setAuthorizedHandler, setUserInfo } = userSlice.actions;
export default userSlice.reducer;
